const myHttp = {
	baseUrl: process.env.VUE_APP_BASE_API,

	/**
	 * Api呼び出す共通メソッド
	 * @param {*} apiUrl
	 * @param {*} data
	 * @returns
	 */
	postAsync: async function (apiUrl = '', data = {}) {
		// Default options are marked with *
		const baseUrl = this.baseUrl
		console.log('baseUrl:'+ baseUrl)
		const fullUrl = baseUrl + apiUrl
		console.log('fullUrl:'+ fullUrl)
		const response = await fetch(fullUrl, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'omit', // include, *same-origin, omit
			headers: {
				// 'Content-Type': 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: JSON.stringify(data) // body data type must match "Content-Type" header
		})

		const apiResponse = await response.json()
		return apiResponse // parses JSON response into native JavaScript objects
	},
}

export default myHttp
