export default {
	glist: [
		{
			"gameid": "50_193_G",
			"name": "",
			"desc": "",
			"name_zh": "灌篮进阶版",
			"desc_zh": "比比谁才是篮球高手",
			"name_jp": "フラッピーシュート",
			"desc_jp": "ダンクの腕前を競おう",
			"name_en": "Advanced Slam Dunk",
			"desc_en": "Let's compete Who is the best slam dunk",
			"area": 6,
			"img": "/img/jp/game_icon/23-128.png",
			"hasRank": true,
			"hasAsset": false,
			"hasBag": false,
			"priority": 0,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs1/games/193/H5_Chinese/",
			"url_test_zh": "http://test.legendnet.cn/gbst/193/H5_Chinese/",
			"url_en": "https://bgsgame.com/bgs1/games/193/H5_English/",
			"url_test_en": "http://test.legendnet.cn/gbst/193/H5_English/",
			"url_jp": "https://bgsgame.com/bgs1/games/193/H5_Japanese/",
			"url_test_jp": "http://test.legendnet.cn/gbst/193/H5_Japanese/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},
		{
			"gameid": "50_4_B",
			"name": "",
			"desc": "",
			"name_zh": "部落战争",
			"desc_zh": "拿起弓箭，部落开战！",
			"name_jp": "部族の弓",
			"desc_jp": "弓を手に取り、戦を始めよう！",
			"name_en": "Tribal Warfare",
			"desc_en": "Pick up the bow and arrow, join the Horde war!",
			"area": 8,
			"img": "/img/jp/game_icon/10-128.png",
			"hasRank": true,
			"hasAsset": false,
			"hasBag": false,
			"priority": 0,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs1/games/4/H5_Chinese/",
			"url_test_zh": "http://test.legendnet.cn/gbst/4/H5_Chinese/",
			"url_en": "https://bgsgame.com/bgs1/games/4/H5_English/",
			"url_test_en": "http://test.legendnet.cn/gbst/4/H5_English/",
			"url_jp": "https://bgsgame.com/bgs1/games/4/H5_Japanese/",
			"url_test_jp": "http://test.legendnet.cn/gbst/4/H5_Japanese/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},

		{
			"gameid": "50_242_L",
			"name": "",
			"desc": "",
			"name_zh": "灵感飞跃",
			"desc_zh": "摩托车夺金大暴走！",
			"name_jp": "超チャリ走　3D版",
			"desc_jp": "チャリの大暴走！",
			"name_en": "Inspiration Leap",
			"desc_en": "Motorcycle run in rampage for gold!",
			"area": 11,
			"img": "/img/jp/game_icon/11-128.png",
			"hasRank": false,
			"hasAsset": false,
			"hasBag": false,
			"priority": 0,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs1/games/242/H5_Chinese/",
			"url_test_zh": "http://test.legendnet.cn/gbst/242/H5_Chinese/",
			"url_en": "https://bgsgame.com/bgs1/games/242/H5_English/",
			"url_test_en": "http://test.legendnet.cn/gbst/242/H5_English/",
			"url_jp": "https://bgsgame.com/bgs1/games/242/H5_Japanese/",
			"url_test_jp": "http://test.legendnet.cn/gbst/242/H5_Japanese/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},
		{
			"gameid": "50_jump",
			"name": "",
			"desc": "",
			"name_zh": "神奇动物大跳跃",
			"desc_zh": "每天和萌萌的小动物，一起跳跃，获得更多积分吧~！",
			"name_jp": "ファンタスティック・アニマル・ジャンプ",
			"desc_jp": "毎日かわいい小動物たちと一緒にジャンプして、ポイントをたくさんゲットしよう～。",
			"name_en": "Fantastic animal jump",
			"desc_en": "Jump with the cute little animals every day and get more points!",
			"area": 6,
			"img": "/img/jp/game_icon/50_jump.png",
			"hasRank": true,
			"hasAsset": false,
			"hasBag": false,
			"priority": 1,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs2/gogo/cn/web-mobile/",
			"url_test_zh": "",
			"url_en": "https://bgsgame.com/bgs2/gogo/en/web-mobile/",
			"url_test_en": "",
			"url_jp": "https://bgsgame.com/bgs2/gogo/jp/web-mobile/",
			"url_test_jp": "",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},

		{
			"gameid": "50_football",
			"name": "",
			"desc": "",
			"name_zh": "热血足球",
			"desc_zh": "激情球场，热血碰撞，集中精神，赢得比赛！",
			"name_jp": "熱血サッカー",
			"desc_jp": "情熱球場、熱血衝突、集中精神、試合優勝！",
			"name_en": "Passionate football",
			"desc_en": "Passion on the pitch, blood on the floor, concentration on the game and winning!",
			"area": 6,
			"img": "/img/jp/game_icon/50_football.png",
			"hasRank": true,
			"hasAsset": false,
			"hasBag": false,
			"priority": 11,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs2/football/cn/web-mobile/",
			"url_test_zh": "http://119.27.161.25/bgsGame/football/cn/web-mobile/",
			"url_en": "https://bgsgame.com/bgs2/football/en/web-mobile/",
			"url_test_en": "http://119.27.161.25/bgsGame/football/en/web-mobile/",
			"url_jp": "https://bgsgame.com/bgs2/football/jp/web-mobile/",
			"url_test_jp": "http://119.27.161.25/bgsGame/football/jp/web-mobile/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},
		{
			"gameid": "50_slot",
			"name": "",
			"desc": "",
			"name_zh": "我是大富豪",
			"desc_zh": "百分百还原经典赌场游戏",
			"name_jp": "ビーファンボードゲーム",
			"desc_jp": "クラシックカジノゲームの完璧な再現",
			"name_en": "I'm a rich man",
			"desc_en": "Perfectly restore classic casino games",
			"area": 2,
			"img": "/img/jp/game_icon/50_slot.png",
			"hasRank": false,
			"hasAsset": false,
			"hasBag": false,
			"priority": 12,
			"seeAfter": 0,
			"url": "",
			"url_test": "",
			"url_zh": "http://s1.bgsgame.com/slotRealese/cn/web-mobile/",
			"url_test_zh": "http://150.109.196.32/slot/cn/web-mobile/",
			"url_en": "http://s1.bgsgame.com/slotRealese/en/web-mobile/",
			"url_test_en": "http://150.109.196.32/slot/en/web-mobile/",
			"url_jp": "http://s1.bgsgame.com/slotRealese/jp/web-mobile/",
			"url_test_jp": "http://150.109.196.32/slot/jp/web-mobile/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},

		{
			"gameid": "50_wawa",
			"name": "",
			"desc": "",
			"name_zh": "娃娃机",
			"desc_zh": "瞅准时机，看准位置，抓取你心爱的娃娃吧！",
			"name_jp": "ワクワクキャッチー",
			"desc_jp": "楽しみながらアイテムゲット！",
			"name_en": "Catch the doll",
			"desc_en": "Look at the right time, spot the location, and grab your beloved doll!",
			"area": 0,
			"img": "/img/jp/game_icon/50_wawa.png",
			"hasRank": true,
			"hasAsset": false,
			"hasBag": false,
			"priority": 26,
			"seeAfter": 1627801200000,
			"url": "",
			"url_test": "",
			"url_zh": "https://bgsgame.com/bgs2/wawa/cn/web-mobile/",
			"url_test_zh": "http://49.232.108.240:8080/wwj/cn/web-mobile/",
			"url_en": "https://bgsgame.com/bgs2/wawa/en/web-mobile/",
			"url_test_en": "http://49.232.108.240:8080/wwj/cn/web-mobile/",
			"url_jp": "https://bgsgame.com/bgs2/wawa/jp/web-mobile/",
			"url_test_jp": "http://119.27.161.25/bgsGame/wawa/jp/web-mobile/",
			"enter_btn": "",
			"desc_url": "",
			"isTestServerGame": false
		},
	]
}