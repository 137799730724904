import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import myHttp from './plugins/myHttp'
import Toasted from "vue-toasted"
import router from "./router"
import jquery from 'jquery'


import games from './common/games.js';

Vue.config.productionTip = false
Vue.prototype.$myHttp = myHttp;
Vue.prototype.$games = games;
Vue.prototype.$ = jquery;

Vue.use(Toasted);

new Vue({
	el: "#app",
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app')

